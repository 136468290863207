export const environment = {
    production: true,
    GOOGLE_CLIENT_ID: '62198175306-h477rqb0lmglnlolib9pn2m9pjudrm2h.apps.googleusercontent.com',
    ROOT_URL: 'https://blackwidow.fyle.tech',
    APP_URL: 'https://blackwidow1.fyle.tech/app',
    APP_ROUTER_URL: 'https://blackwidow-accounts.fyle.tech',
    ROUTER_URL: 'https://blackwidow-accounts.fyle.tech',
    SENTRY_DSN: 'https://971bdf3cefe24ef88d6e1cbb65b5d535@sentry.io/3554166',
    SEND_ERROR_TO_SENTRY: true,
    SENTRY_TRACES_SAMPLE_RATE: 0.2,
    ENVIRONMENT: 'blackwidow',
    RELEASE: '2506e56dcf055479ced6585bf0c241e081e16f37',
    TARGET: 'fyle',
    GOOGLE_MAPS_API_KEY: 'AIzaSyCnRehxBEzK3HYLvflpf2tCnT75Fqq7q9s',
    DWOLLA_ENVIRONMENT: 'sandbox',
    LAUNCH_DARKLY_CLIENT_ID: '61fb848da00c1016c73fbe54',
    NETLIFY_FYLE_APP_KEY: 'undefined',
    NETLIFY_FUNCTION_URL: 'https://fyle-app-blackwidow.netlify.app/.netlify/functions',
    DEPLOY_LOGS_WEBHOOK_URL: 'https://hooks.slack.com/services/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',
    C1_DEPLOY_LOGS_WEBHOOK_URL: 'undefined',
    SLACK_COPILOT_FEEDBACK_WEBHOOK: 'https://hooks.slack.com/services/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',
    NETLIFY_ACCESS_TOKEN: 'lGbKhYoWjUZqXtRsietrw4vIS27AMCI5C6umcrxZQbQ',
    MIXPANEL_PROJECT_TOKEN: 'dce787f3a74f5ede8264af230a4b4652',
    MIXPANEL_PROXY_URL: 'https://blackwidow.fyle.tech/mixpanel',
    USE_MIXPANEL_PROXY: 'false',
    ENABLE_MIXPANEL: 'false',
    YODLEE_FAST_LINK_URL: 'undefined',
  };